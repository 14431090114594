@import "../node_modules/bootstrap/scss/bootstrap.scss";
.bg-purple{
  background-color: purple;
}
.bg-red{
  background-color: red;
}
.bg-blue{
  background-color: blue;
}
.bg-yellow{
  background-color: yellow;
}
.bg-cyan{
  background-color: cyan;
}
.bg-gradient-contact{
  background: #667db6;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #667db6, #0082c8, #0082c8, #667db6);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #667db6, #0082c8, #0082c8, #667db6); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}  
.bg-custom1{
  background-color: #F1F1F4;
}
.bg-custom2{
  background-color: #A8B0BD;
}
.bg-custom3{
  background-color: #E4C1A2;
}
.bg-custom4{
  background-color: #969DAB;
}
.bg-custom5{
  background-color: #707A8C;
}

.text-custom-dark{
  text-decoration-color: #3d3d3d;
}
